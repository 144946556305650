import React, { useEffect } from 'react';
import ClockLoader from 'react-spinners/ClockLoader';
import {
  Button,
  Chip,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input
} from '@material-tailwind/react';
import './style.css';

import Header from '../Header';
import useToken from '../App/useToken';
import useStorage from '../App/useStorage';
import useWeb3 from '../App/useWeb3';

let isDelete = false;

function Stack({ children, direction, spacing }) {
  const stack = React.Children.toArray(children);
  const directionClass = direction ? `flex-${direction}` : '';
  const spacingClass = spacing ? `gap-${spacing}` : '';
  return (
    <div className={`flex flex-wrap ${directionClass} ${spacingClass}`}>
      {stack.map((child, i) => (
        <div key={i} className="flex-3 m-2">
          {child}
        </div>
      ))}
    </div>
  );
}

function Profile() {
  const [userInfo, setUserInfo] = React.useState({});
  const { account } = useWeb3();
  const [loading, setLoading] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState({});
  const { token, setToken } = useToken();
  const [open, setOpen] = React.useState(false);
  const [newWallet, setNewWallet] = React.useState('');
  const { user, setUser } = useStorage();
  useEffect(() => {
    if (errorMsg.code === 401) {
      setToken({});
      // setUser({});
    }
  }, [errorMsg, setToken]);
  useEffect(() => {
    loadProfile();
  }, []);
  const loadProfile = async () => {
    setLoading(true);
    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API_URL}/v1/users/${user.id}`, {
      method: 'GET',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      }
    })
      .then((r) => r.json())
      .then((data) => {
        setLoading(false);
        if (data.code) {
          setErrorMsg(data);
        } else {
          setUserInfo(data);
        }
      })
      .catch((err) => {
        setErrorMsg(err);
        setLoading(false);
      });
  };

  const handleOpen = () => {
    setErrorMsg({});
  };
  const handleClose = () => {
    setErrorMsg({});
    setOpen(false);
  };
  const activateWallet = (wallet) => {
    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API_URL}/v1/wallets/${user.id}`, {
      method: 'PATCH',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        wallet
      })
    })
      .then((r) => r.json())
      .then((data) => {
        if (data.code) {
          setErrorMsg(data);
        } else {
          const userUpdate = Object.assign({}, user);
          userUpdate.wallet = wallet;
          setUser(userUpdate);
          alert('Wallet Activated');
        }
      })
      .catch((err) => {
        setErrorMsg(err.message);
      });
  };
  const deleteWallet = (wallet) => {
    isDelete = true;
    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API_URL}/v1/wallets/${user.id}/${wallet}`, {
      method: 'DELETE',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      }
    })
      .then((r) => r.text())
      .then((data) => {
        loadProfile();
      })
      .catch((err) => {
        setErrorMsg(err.message);
      })
      .finally(() => {
        isDelete = false;
      });
  };
  const handleSubmit = () => {
    // eslint-disable-next-line no-undef
    fetch(`${process.env.REACT_APP_API_URL}/v1/wallets/${user.id}`, {
      method: 'POST',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        wallet: newWallet
      })
    })
      .then((r) => r.json())
      .then((data) => {
        if (data.code) {
          setErrorMsg(data);
        } else {
          setUserInfo(data);
          setOpen(false);
        }
      })
      .catch((err) => {
        setErrorMsg(err.message);
      });
  };

  return (
    <div className=" v-screen flex justify-center items-center">
      <div className="container mx-auto min-w-full min-h-screen bg-blue-600 bg-blue-gradient">
        <Header active="/account/profile" />
        <div className="container mx-auto flex w-48 my-10">
          <ClockLoader color="lightBlue" class="mx-auto" loading={loading} size={150} />
        </div>
        {errorMsg.message && (
          <div className="flex items-center mb-5">
            <div
              className="w-96 bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-auto"
              role="alert"
            >
              <strong className="font-bold">ERROR!&nbsp;</strong>
              <span className="block sm:inline">{errorMsg.message}</span>
            </div>
          </div>
        )}
        {Object.keys(userInfo).length > 0 && userInfo.profile != null && (
          <div className="container my-10 bg-white p-10 mx-auto rounded-lg">
            <table className="w-full">
              <tbody>
                <tr>
                  <th className="text-left">Wallet</th>
                  <td>{userInfo.wallet}</td>
                </tr>
                <tr>
                  <th className="text-left">Contribution Wallet</th>
                  <td>{userInfo.profile.countributingWallet}</td>
                </tr>
                <tr>
                  <th className="text-left">Staking Token</th>
                  <td>{userInfo.profile.stakedToken}</td>
                </tr>
                {userInfo.profile.transactionHash && (
                  <tr>
                    <th className="text-left">Transaction Hash</th>
                    <td>
                      <a
                        className="text-red-600"
                        target="_blank"
                        href={`https://bscscan.com/tx/${userInfo.profile.transactionHash}`}
                        rel="noreferrer"
                      >
                        {userInfo.profile.transactionHash}{' '}
                      </a>
                    </td>
                  </tr>
                )}
                <tr>
                  <th className="text-left ">Telegram</th>
                  <td>{userInfo.profile.telegram}</td>
                </tr>
                <tr>
                  <th className="text-left ">Contribution Amount</th>
                  <td>{userInfo.profile.contributionAmount}</td>
                </tr>
                <tr>
                  <th className="text-left ">Contribution Date</th>
                  <td>{userInfo.profile.ContributionDate}</td>
                </tr>
                <tr>
                  <th className="text-left ">Round</th>
                  <td>{userInfo.profile.round}</td>
                </tr>
                <tr>
                  <th className="text-left ">Price</th>
                  <td>{userInfo.profile.price}</td>
                </tr>
                <tr>
                  <th colSpan={2} className="text-left ">
                    Comments
                  </th>
                </tr>
                <tr>
                  <td className="text-left ">{userInfo.profile.comments}</td>
                </tr>
                <tr>
                  <th className="text-left ">Wallets</th>
                  <td>
                    <Dialog open={open} handler={handleOpen}>
                      <DialogHeader>Add New Wallet</DialogHeader>
                      <DialogBody divider>
                        <Stack direction="row">
                          <Input
                            type={'text'}
                            value={newWallet}
                            label="Wallet  Address"
                            className="w-96"
                            variant="outlined"
                            onChange={(e) => {
                              setNewWallet(e.target.value);
                            }}
                          />
                          {errorMsg.message && (
                            <div className="flex items-center mb-5">
                              <div
                                className="w-full bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mx-auto"
                                role="alert"
                              >
                                <strong className="font-bold">ERROR!&nbsp;</strong>
                                <span className="block sm:inline">{errorMsg.message}</span>
                              </div>
                            </div>
                          )}
                        </Stack>
                      </DialogBody>
                      <DialogFooter>
                        <Button variant="text" color="red" onClick={handleClose} className="mr-1">
                          <span>Cancel</span>
                        </Button>
                        <Button variant="gradient" color="green" onClick={handleSubmit}>
                          <span>Confirm</span>
                        </Button>
                      </DialogFooter>
                    </Dialog>
                    <Stack direction={'row'} spacing={2}>
                      {userInfo.wallets &&
                        userInfo.wallets.map((u, i) => {
                          return (
                            <Chip
                              dismissible={{
                                onClose: (e) => {
                                  e.preventDefault();
                                  deleteWallet(u.wallet);
                                }
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                if (isDelete === false) {
                                  activateWallet(u.wallet);
                                }
                              }}
                              color="gray"
                              value={u.wallet}
                            />
                          );
                        })}
                    </Stack>

                    <Button
                      variant="text"
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      Add New Wallet
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}

export default Profile;
