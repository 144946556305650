import { Button } from '@material-tailwind/react';
import React, { useState } from 'react';
import { constructURLForRoute, VALIDATE_USER } from '../../urls';
import useWeb3 from '../App/useWeb3';

function Login({ setAccount, setUser }) {
  const [error, setError] = useState({});
  const [loading, setLoading] = useState(false);
  const { getAccount } = useWeb3();

  async function loginUser(account) {
    setLoading(true);
    if (account === "" || account === undefined) return;

    return fetch(constructURLForRoute(VALIDATE_USER), {
      method: 'GET',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      },
    }).then(data => data.json()).catch(err => {
      setError(err);
      alert(err.message);
    });
  }

  const connectWallet = async () => {
    const account = await getAccount();
    const user = await loginUser(account);
    if (user && user.code) {
      setError(user);
      alert(user.message);
    }
    if (user != undefined && user.wallet != undefined) {
      setUser(user);
      setAccount(account);
    }
    setLoading(false);
  }

  return (
    <div className="h-screen flex bg-blue-gradient">
      <div className="w-full max-w-lg m-auto rounded-lg shadow-default py-10 px-16">
        <Button type="submit" size="lg" color="light-blue" variant="filled" onClick={connectWallet} className="m-auto flex items-center justify-center gap-2" ripple={true}>
          <img src={process.env.PUBLIC_URL + '/metamask.svg'} alt="metamask" className="h-10 w-10" />
          Connect your wallet
        </Button>
      </div>
    </div >
  );
}

export default Login;
