import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import Header from '../Header';
import { Dialog, DialogBody } from '@material-tailwind/react';
import useToken from '../App/useToken';
import useNetwork from '../App/useNetwork';
import 'react-loading-skeleton/dist/skeleton.css';
import ReactTooltip from 'react-tooltip';
import useStorage from '../App/useStorage';
import { constructURLForNetwork, FETCH_VESTING_URL, SYNC_MAGIC_URL, SYNC_VESTING_URL } from '../../urls';
import useWeb3 from '../App/useWeb3';
import { io } from 'socket.io-client';
import Message from '../Message';
import { IDOContext } from '../../context/IDOContext';
import { AiFillDownCircle, AiFillDownSquare, AiFillUpCircle, AiOutlineDown, AiTwotoneDownCircle } from 'react-icons/ai';
import { RiHandCoinLine } from 'react-icons/ri';
function IDO() {
  const { account } = useWeb3();
  const { claimAmount } = useContext(IDOContext);
  const { user, setUser } = useStorage();
  const { network } = useNetwork();
  const [walletAddress, setWalletAddress] = React.useState(''); // 0x5d7A6fB0AAA4B79c93DCD7A43039c49AB83BC995
  const [vestingDetails, setVestingDetails] = React.useState({
    allocations: [],
    roundInfo: [],
    project: null
  });
  const [showModal, setShowModal] = React.useState(false);
  const [AllData, setAllData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [syncing, setSyncing] = React.useState(false);
  const [errorMsg, setErrorMsg] = React.useState({});
  const [showPrice, setShowPrice] = useState(true);
  const [claimableItems, setClaimableItems] = useState([]);
  const [socket, setSocket] = useState(io.connect(process.env.REACT_APP_API_URL));
  const [claimableAllocations, setClaimableAllocations] = useState([]);

  useEffect(() => {
    if (account != null) {
      getProjectDetails();
      socket.emit('join', { clientID: account });
      socket.on('fetch_idodata', function (data) {
        getProjectDetails();
      });
    }
  }, [account]);
  const performClaimToken = async (contractAddress, index) => {
    await claimAmount(contractAddress, account, index)
  }
  const getProjectDetails = () => {
    setLoading(true);
    fetch(constructURLForNetwork(network, FETCH_VESTING_URL), {
      method: 'GET',
      headers: {
        wallet: account
      }
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        if (data.code) {
          setErrorMsg(data);
        } else {
          setAllData(data);
        }
      })
      .catch(() => {
        setLoading(false);
      });
    // });
  };
  const syncVestingInfo = () => {
    setSyncing(true);
    fetch(constructURLForNetwork(network, SYNC_MAGIC_URL), {
      method: 'POST',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ walletAddress })
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.syncDt) {
          const syncAfter = moment.unix(data.syncDt).fromNow()
          setErrorMsg({ message: `You can sync only after ${syncAfter}`, type: 'error' });
        } else {
          setErrorMsg({});
        }
        // setSyncing(false);
        // if (data.code) {
        //   setErrorMsg(data);
        // } else {
        //   getProjectDetails();
        //   setErrorMsg({});
        //   alert('Sync Successfully');
        // }
      })
      .catch(() => {
        setSyncing(false);
      });
    // });
  };
  const selectVestingDetails = (vinfo) => {
    //console.log(project);
    setClaimableAllocations([]);
    if (vestingDetails && vestingDetails.project && vinfo.project.contract === vestingDetails.project.contract) {
      setVestingDetails({});
    } else {
      setVestingDetails(vinfo);
      const allocationInfos = [];
      AllData.filter(d => d.project.projectTokenSymbol === vinfo.project.projectTokenSymbol).map(item => {
        allocationInfos.push(renderAllocationInfo(item.project, item.allocationInfo, item));
      })
      setClaimableAllocations([...allocationInfos.flat().filter(p => p != null)]);
    }
  };
  const renderProjectListing = () => {
    const tokenCollection = [];
    if (AllData.length === 0) {
      return null;
    }
    let aggData = AllData.map((p) => {
      if (tokenCollection.indexOf(p.project.projectTokenSymbol) > -1) {
        return null;
      }
      tokenCollection.push(p.project.projectTokenSymbol);
      let vestedTokens = 0;
      let AllocatedTokens = 0;
      let totalClaimed = 0;
      let readyToClaim = 0;
      let nextVestedTokens = 0;
      let linearToken = 0;
      let nextVestingDate = 0;
      let lastVestingDate = 0;
      AllData.filter((d) => d.project.projectTokenSymbol === p.project.projectTokenSymbol).forEach(
        (item) => {
          vestedTokens += parseFloat(item.vestedTokens);
          linearToken += parseFloat(item.linearToken);
          readyToClaim += parseFloat(item.readyToClaim);
          nextVestedTokens += parseFloat(item.nextVestedTokens);
          nextVestingDate = parseInt(item.nextVestingDate);
          lastVestingDate = parseInt(item.lastVestingDate);

          if (lastVestingDate < moment().unix()) {
            lastVestingDate = 0;
          }
          AllocatedTokens += parseFloat(item.allocatedTokens);
          totalClaimed += parseFloat(item.totalClaimed);
        }
      );
      // (
      // showPrice ? p.stake.toNumber() * p.project.usdPrice : p.stake
      // ).toFixed(2)
      return {
        allocatedTokens: showPrice ? AllocatedTokens * p.usdPrice : AllocatedTokens,
        claimed: showPrice ? totalClaimed * p.usdPrice : totalClaimed,
        vested: showPrice ? vestedTokens * p.usdPrice : vestedTokens,
        project: p.project,
        readyToClaim: showPrice ? readyToClaim * p.usdPrice : readyToClaim,
        nextVestedTokens,
        nextVestingDate,
        lastVestingDate,
        linearToken,
        vestingInfo: p.vestingInfo
      };
    });
    aggData = aggData.filter((p) => p != null).sort((a, b) => b.readyToClaim - a.readyToClaim);
    return (
      <ul className="bg-white p-10 pt-2 mx-auto rounded-lg">
        <div className="inline-flex rounded-md shadow-sm float-right " role="group">
          <button
            type="button"
            onClick={() => {
              setShowPrice(true);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-l-lg border border-blue-900 hover:bg-blue-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
          >
            <i className="fa fa-dollar-sign"></i>
            Value
          </button>
          <button
            type="button"
            onClick={() => {
              setShowPrice(false);
            }}
            className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-r-lg border border-blue-900 blue-gray-900 hover:bg-blue-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700"
          >
            <i className="fas fa-coins" />
            Token
          </button>
        </div>
        <div className="inline-flex pt-3 w-full">&nbsp;</div>
        {aggData.map((p) => {
          var selectedProject = false;
          if (vestingDetails.project != null && vestingDetails.project.name === p.project.name) {
            selectedProject = true;
          }
          if (p === null || p.allocatedTokens === p.claimed) {
            return null;
          }
          const totalAnalysis = [];
          totalAnalysis.push({
            key: 'Total',
            value:
              (showPrice ? '$' : '') +
              (isNaN(p.allocatedTokens) ? '0' : Math.round(p.allocatedTokens)),
            color: 'yellow'
          });
          totalAnalysis.push({
            key: 'Vested',
            value: (showPrice ? '$' : '') + (isNaN(p.vested) ? '0' : Math.round(p.vested)),
            color: 'indigo'
          });
          totalAnalysis.push({
            key: 'Claimed',
            value: (showPrice ? '$' : '') + (isNaN(p.claimed) ? '0' : Math.round(p.claimed)),
            color: 'blue'
          });
          totalAnalysis.push({
            key: 'Ready To Claim',
            value:
              (showPrice ? '$' : '') + (isNaN(p.readyToClaim) ? '0' : Math.round(p.readyToClaim)),
            color: 'green'
          });

          return (
            <li className="flex items-center mb-5 border-b-900 max-auto c-hand" style={{ 'align-items': 'flex-start' }}>
              {/* Token Symbol */}
              {p?.project?.logo != null ?
                <img src={p.project.logo} className="w-16 rounded-full" alt={p.project.name} /> :
                <div className="font-bold h-16 w-16 text-blue-700 rounded-full font-mono">{p.project.name}</div>
              }
              <div className=" mx-5">
                <button data-toggle="collapse" data-target={`#${p.project.name}`}
                  className="text-blue-900 "
                  aria-expanded={(vestingDetails && vestingDetails.project && vestingDetails.project.name === p.project.name)} aria-controls={p.project.name}
                  role="button"
                  onClick={() => {
                    selectVestingDetails(p);
                  }}
                >  {p.project.name} &nbsp;&nbsp;&nbsp;&nbsp;
                  <small className="text-blue-900 mt-1 mx-5">{p.project.projectTokenSymbol}
                    {selectedProject &&
                      <AiFillUpCircle style={{ display: 'inline', fontSize: '21px' }} />
                    }
                    {!selectedProject &&
                      <AiFillDownCircle style={{ display: 'inline', fontSize: '21px' }} />
                    }
                  </small>

                </button>
                {AllData.filter(
                  (d) => d.project.projectTokenSymbol === p.project.projectTokenSymbol
                ).map((item, i) => (
                  <a
                    key={item.project.projectTokenSymbol + i}
                    href={item.project.url}
                    data-tip={item.project.name}
                    target="_blank"
                    className="bg-blue-100 inline-flex text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-900"
                    rel="noreferrer"
                  >
                    {/* TODO: s/lunchpad/launchpad */}
                    {item.project.lunchpad}
                  </a>
                ))}
                <p className="flex flex-col md:flex-row">
                  {totalAnalysis.map((item, i) => (
                    <button
                      disabled
                      key={'ana' + i}
                      className="h-10 w-48 text-xs my-2 mx-1 px-1 text-blue-100 transition-colors duration-150 bg-green-700 rounded-lg focus:shadow-outline"
                    >
                      <span className="mr-2">{item.key}</span>
                      <span className="inline-flex float-right justify-right px-2 py-1 text-xs font-bold leading-none text-blue-100 rounded-full text-right">
                        {item.value}
                      </span>
                    </button>
                  ))}
                </p>
                <p className="text-gray-900">
                  {p.nextVestedTokens > 0 && p.nextVestingDate > 0 ? (
                    <small className="bg-green-100 inline-flex text-green-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                      {Math.round(p.nextVestedTokens)} would be available{' '}
                      {moment.unix(p.nextVestingDate).fromNow()}
                    </small>
                  ) : (
                    ''
                  )}
                  {p.linearToken > 0 ? (
                    <small className="bg-green-100 inline-flex text-green-800 text-xs font-semibold px-2.5 py-0.5 rounded dark:bg-green-200 dark:text-green-900">
                      Approximate {Math.round(p.linearToken)} tokens available per day
                    </small>
                  ) : (
                    ''
                  )}
                  {p.lastVestingDate > 0 && p.lastVestingDate > 0 ? (
                    <small className="bg-blue-100 inline-flex text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-900">
                      Vesting would be completed {moment.unix(p.lastVestingDate).fromNow()}
                    </small>
                  ) : (
                    ''
                  )}
                  {p.vestingInfo != null ? (
                    <small className="bg-blue-100 inline-flex text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-900">
                      {p.vestingInfo}
                    </small>
                  ) : (
                    ''
                  )}
                </p>
                {(vestingDetails && vestingDetails.project && vestingDetails.project.name === p.project.name) &&
                  <p className={`flex flex-col md:flex-row`} id={p.project.name}>
                    {renderProjectToken()}
                  </p>
                }
              </div>


            </li>
          );
        })}

        <ReactTooltip />
      </ul>
    );
  };
  const renderAllocationInfo = (project, allocationInfo, vinfo) => {
    try {
      console.log(vinfo);
      const infoWallet = JSON.parse(allocationInfo);
      let vestedTokens = 0;
      let allocatedTokens = 0;
      let totalClaimed = 0;
      let nextVestedTokens = 0;
      let linearToken = 0;
      let nextVestingDate = 0;
      let lastVestingDate = 0;

      const uniqueAllocation = [];
      infoWallet[0].forEach((item) => {
        if (uniqueAllocation.indexOf(item) < 0) {
          uniqueAllocation.push(item);
        }
      });

      vestedTokens =
        infoWallet[1].reduce((partialSum, a, index) => {
          const dt = infoWallet[2][index];
          if (index % 2 === 0 && dt < moment().unix()) {
            return partialSum + a;
          }
          return partialSum;
        }, 0) / '1'.padEnd(project.decimals + 1, '0');
      // calculate recent vesting tokens and date
      if (uniqueAllocation.length <= 2 && infoWallet[2][infoWallet[2].length - 2] !== infoWallet[2][infoWallet[2].length - 1]) {
        // liner calculation
        const totalToken = infoWallet[1][infoWallet[1].length - 1] / '1'.padEnd(project.decimals + 1, '0');
        const dateDiff = moment.duration(
          moment.unix(infoWallet[2][infoWallet[2].length - 1]).diff(moment.unix(infoWallet[2][infoWallet[2].length - 2]))
        );
        linearToken += Math.round(totalToken / dateDiff.asDays());
      } else {
        nextVestedTokens +=
          infoWallet[1].reduce((partialSum, a, index) => {
            const dt = infoWallet[2][index];
            if (
              index % 2 !== 0 &&
              ((dt > moment().unix() && nextVestingDate === 0) || (nextVestingDate === dt && nextVestingDate !== 0))
            ) {
              nextVestingDate = dt;
              return partialSum + a;
            }
            return partialSum;
          }, 0) / '1'.padEnd(project.decimals + 1, '0');
      }
      var isLinearAdded = false;
      return infoWallet[3].map((item, index) => {
        const dt = parseInt(infoWallet[2][index]);
        const todaydt = moment().unix();
        if (index % 2 === 0 && ((item === '0' && dt < todaydt) || linearToken > 0)) {
          const aa = [...claimableItems];

          if (aa.find(d => d.name === project.name) === undefined) {
            //aa.length = 0;
            aa.push({ name: project.name })
            setClaimableItems(aa);
          }
          if ((isLinearAdded && linearToken > 0) || (linearToken > 0 && (vinfo.vestedTokens - vinfo.totalClaimed) <= 0))
            return null
          isLinearAdded = true;
          return <tr>
            <td>{project.name + ' - ' + project.lunchpad}</td>
            {linearToken > 0 &&
              <td>{moment().format("MM/DD/YYYY")}</td>
            }
            {linearToken <= 0 &&
              <td>{moment.unix(infoWallet[2][index]).format("MM/DD/YYYY")}</td>
            }
            <td className='text-right'>{Math.round(linearToken > 0 ? (vinfo.vestedTokens - vinfo.totalClaimed) : (infoWallet[1][index] / '1'.padEnd(project.decimals + 1, '0')))}</td>
            <td className='text-right'>
              <button className="inline-flex items-center px-4 py-2 bg-green-600 hover:bg-red-700 text-white text-sm font-medium rounded-md" onClick={() => { performClaimToken(project.contract, index / 2) }}>
                <RiHandCoinLine className="mx-2" />
                Claim
              </button>
            </td>
          </tr>
        } else {
          return null;
        }
      })
    } catch (error) {
      console.log(error);
      return null;
    }

  }
  const renderProjectToken = () => {
    if (vestingDetails.project === null) {
      return null;
    }
    return (
      <table className="table-auto w-full">
        <thead>
          <tr>
            <th className="border-b dark:border-slate-600 font-medium p-1 pl-8 pt-0 pb-1 text-slate-400 dark:text-slate-200 text-left">
              Launchpad
            </th>

            <th className="border-b dark:border-slate-600 whitespace-nowrap font-medium p-1 pl-8 pt-0 pb-1 text-slate-400 dark:text-slate-200 text-right">
              Ready to Claim
            </th>
            <th className="border-b dark:border-slate-600 whitespace-nowrap font-medium p-1 pl-8 pt-0 pb-1 text-slate-400 dark:text-slate-200 text-right">
              Total
            </th>
            <th className="border-b dark:border-slate-600 whitespace-nowrap font-medium p-1 pl-8 pt-0 pb-1 text-slate-400 dark:text-slate-200 text-right">
              Vested
            </th>
            <th className="border-b dark:border-slate-600  font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-right">
              Claimed
            </th>
          </tr>
        </thead>
        <tbody className="bg-white dark:bg-slate-800">
          {AllData.filter(
            (d) => d.project.projectTokenSymbol === vestingDetails.project.projectTokenSymbol
          ).map((item, i) => {
            const { vestedTokens } = item;
            const AllocatedTokens = item.allocatedTokens;
            const { totalClaimed } = item;
            return (
              <tr key={new Date().getTime() + 'a' + i}>
                <td className="border-b border-slate-100 dark:border-slate-700 p-1 pl-8 text-slate-500 dark:text-slate-400">
                  {item.project.name}
                  {/* TODO: s/lunchpad/launchpad */}
                  <small>-{item.project.lunchpad}</small>
                </td>

                <td className="border-b border-slate-100 dark:border-slate-700 p-1 pl-8 text-slate-500 dark:text-slate-400 text-right">
                  {Math.round(vestedTokens - totalClaimed)}
                </td>
                <td className="border-b border-slate-100 dark:border-slate-700 p-1 pl-8 text-slate-500 dark:text-slate-400 text-right">
                  {Math.round(AllocatedTokens)}
                </td>
                <td className="border-b border-slate-100 dark:border-slate-700 p-1 pl-8 text-slate-500 dark:text-slate-400 text-right">
                  {Math.round(vestedTokens)}
                </td>
                <td className="border-b border-slate-100 dark:border-slate-700 p-1 pl-8 text-slate-500 dark:text-slate-400 text-right">
                  {Math.round(totalClaimed)}
                </td>
              </tr>
            );
          })}
          {claimableAllocations.length > 0 &&
            <>
              <tr>
                <td colSpan={6}>
                  <h3 className="text-lg mt-2">Claimable Allocation</h3>
                  <hr />
                </td>
              </tr>
              <tr>
                <td colSpan={6} >
                  <table className="table-auto w-full text-dark  dark:bg-slate-800">

                    <thead>
                      <tr>
                        <th className="text-left">Launchpad</th>
                        <th className="text-left ">
                          Date
                        </th>
                        <th className="text-right w-32">
                          Tokens
                        </th>
                        <th className="text-right w-32">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {claimableAllocations.map((item, i) => {
                        return (
                          <>
                            {item}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                </td>

              </tr>
            </>}
        </tbody>
      </table>
    );
  };


  return (
    <div className=" v-screen flex justify-center items-center">
      <div className="container mx-auto min-w-full min-h-screen bg-blue-600 bg-blue-gradient">
        <Header syncButton={true} onSyncClick={() => { syncVestingInfo() }} active="/ido" />
        <Message type={errorMsg.type} message={errorMsg.message} />
        <div className="container mx-auto flex my-10">


          {renderProjectListing()}</div>
        {/* <Dialog size="lg" open={showModal} handler={() => setShowModal(false)}>
          <DialogBody>{renderProjectToken()}</DialogBody>
        </Dialog> */}
      </div>
    </div>
  );
}

export default IDO;
