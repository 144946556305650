import React, { useEffect } from 'react';
import moment from 'moment';
import { BigNumber } from 'bignumber.js';
import { Dialog, DialogBody } from '@material-tailwind/react';
import Header from '../Header';
import useToken from '../App/useToken';
import useNetwork from '../App/useNetwork';

import 'react-loading-skeleton/dist/skeleton.css';
import useStorage from '../App/useStorage';
import { constructURLForNetwork, FETCH_STAKING_URL, SYNC_MAGIC_URL, SYNC_TRANSACTIONS_URL } from '../../urls';
import useWeb3 from '../App/useWeb3';
import { useState } from 'react';
import { io } from 'socket.io-client';
import Message from '../Message';

function Staking() {
  const { user, setUser } = useStorage();
  const { account } = useWeb3();
  const { network } = useNetwork();

  const [showModal, setShowModal] = React.useState(false);
  const [currentToken, setCurrentToken] = React.useState({});
  const [stakingProjects, setStakingProjects] = React.useState([]);
  const [syncing, setSyncing] = React.useState(false);
  const [showPrice, setShowPrice] = React.useState(true);
  const [socket, setSocket] = useState(io.connect(process.env.REACT_APP_API_URL));
  const [errorMsg, setErrorMsg] = React.useState({ type: 'error', message: '' });
  // Reloads page if user changes
  useEffect(() => {
    if (account != null) {
      fetchStaking();
      socket.emit('join', { clientID: account });
      socket.on('fetch_stakingdata', function (data) {
        fetchStaking();
      });
    }

  }, [account]);

  // fetchStaking fetches staking details using the API and populates state.
  const fetchStaking = () => {
    fetch(constructURLForNetwork(network, FETCH_STAKING_URL), {
      method: 'GET',
      headers: {
        wallet: account
      }
    })
      .then(res => res.json())
      .then(data => data.code != null ? setErrorMsg(data) : setStakingProjects(data))
      .catch(() => { });
  };

  // syncStaking syncs staking details using the API and updates state.
  const syncStaking = () => {
    setSyncing(true);
    fetch(constructURLForNetwork(network, SYNC_MAGIC_URL), {
      method: 'POST',
      headers: {
        wallet: account,
        'Content-Type': 'application/json'
      }
    })
      .then(res => res.json())
      .then(data => {
        if (data.syncDt) {
          const syncAfter = moment.unix(data.syncDt).fromNow()
          setErrorMsg({ message: `You can sync only after ${syncAfter}`, type: 'error' });
        } else {
          setErrorMsg({});
        }

      })
      .catch(() => setSyncing(false));
  };

  return <StakingPage
    syncStaking={syncStaking}
    syncing={syncing}
    error={errorMsg}
    currentToken={currentToken}
    setCurrentToken={setCurrentToken}
    showModal={showModal}
    setShowModal={setShowModal}
    stakingProjects={stakingProjects}
    showPrice={showPrice}
    setShowPrice={setShowPrice}
  />
}

// TokenStatus displays the token status and value e.g. Staked $100.00
function TokenStatus(props) {
  return (
    <button disabled className="h-10  w-48 text-xs my-2 mx-1 px-1 text-blue-100 transition-colors duration-150 bg-green-700 rounded-lg focus:shadow-outline">
      <span className="mr-2">{props.name}</span>
      <span className="inline-flex float-right justify-right px-2 py-1 text-xs font-bold leading-none text-blue-100 rounded-full text-right">
        {props.value}
      </span>
    </button>
  )
}

// Token displays details for a single token. This is rendered as a row.
function Token(props) {
  return (
    <li key={props.p.project.projectTokenSymbol} className="flex items-center mb-5 border-b-900 max-auto c-hand">
      {/* Token Logo */}
      {props.p.project.logo ? (
        <img
          src={props.p.project.logo}
          className="w-16 rounded-full"
          title={props.p.project.projectTokenSymbol}
          alt={props.p.project.projectTokenSymbol}
        />
      ) : (
        <div className="font-bold h-16 w-16 text-blue-700 rounded-full font-mono">{props.p.project.projectTokenSymbol}</div>
      )}

      <div className="mx-5">
        {/* Token Symbol */}
        <h3
          className="text-blue-900"
          onClick={() => {
            props.setCurrentToken(props.p.project);
            props.setShowModal(true);
          }}>
          {props.p.project.projectTokenSymbol}
          &nbsp;&nbsp;
        </h3>

        {/* Project Links */}
        {/* {console.log(props.p.project.projectTokenSymbol)}
        {console.log(props.stakingProjects.filter(d => d.projectTokenSymbol === props.p.project.projectTokenSymbol))} */}
        {props.stakingProjects
          .filter(d => d.projectTokenSymbol === props.p.project.projectTokenSymbol)
          .filter(p => p != null && (p.stake > 0 || p.unstake > 0 || p.rewards > 0))
          .map(item => (
            <button disabled
              data-tip={item.name}
              onClick={(e) => {
                e.preventDefault();
              }}
              className="bg-blue-100 inline-flex text-blue-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-900">
              {item.name}
            </button>
          ))}

        {/* Token Statuses */}
        <p className="flex flex-col md:flex-row">
          <TokenStatus name='Staked' value={(props.showPrice ? '$' : '') + props.stake} />
          <TokenStatus name='Rewards' value={(props.showPrice ? '$' : '') + props.rewards} />
          <TokenStatus name='Unstaked' value={(props.showPrice ? '$' : '') + props.unstake} />
          <TokenStatus name='Withdrawal' value={props.withdrawal} />
        </p>
      </div>
    </li>
  )
}

// StakingByProject returns the token's staking breakdown by project.
function StakingByProject(props) {
  if (props.currentToken == null) {
    return null;
  }
  return (
    <table className="table-auto">
      <thead>
        <tr>
          <th className="border-b dark:border-slate-600 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left">
            Name
          </th>
          <th className="border-b dark:border-slate-600 whitespace-nowrap font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-right">
            Staked
          </th>
          <th className="border-b dark:border-slate-600 whitespace-nowrap font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-right">
            Rewards
          </th>
          <th className="border-b dark:border-slate-600 whitespace-nowrap font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-right">
            Unstaked
          </th>
          <th className="border-b dark:border-slate-600  font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-right">
            Withdrawal
          </th>
        </tr>
      </thead>
      <tbody className="bg-white dark:bg-slate-800">
        {props.stakingProjects
          .filter(d => d.projectTokenSymbol === props.currentToken.projectTokenSymbol)
          .filter(p => p != null && (p.stake > 0 || p.unstake > 0 || p.rewards > 0))
          .map(item => {
            const { stake, unstake, rewards, withdrawal } = item;
            return (
              <tr>
                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400">
                  {item.name}
                </td>
                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400 text-right">
                  {Math.round(stake)}
                </td>
                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400 text-right">
                  {Math.round(rewards)}
                </td>
                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400 text-right">
                  {unstake > 0 ? Math.round(unstake) : '0'}
                </td>
                <td className="border-b border-slate-100 dark:border-slate-700 p-4 pl-8 text-slate-500 dark:text-slate-400 text-right">
                  {withdrawal > 0 ? moment.unix(withdrawal).fromNow() : '0'}
                </td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
}

// aggregateStakingByToken aggregates staking by token for the specified
// list of projects.
function aggregateStakingByToken(stakingProjects) {
  const tokenSymbols = [];

  // Create a map from project symbols to list of projects
  // let projectMap = new Map();
  // props.stakingProjects.map(project => {
  //   let symbol = project.projectTokenSymbol;
  //   if (!projectMap.has(symbol)) {
  //     projectMap[symbol] = new Array();
  //   }
  //   projectMap[symbol].push(project);
  // });

  let stakingByToken = stakingProjects.map(p => {
    // Returns early if we have already seen the project.
    if (tokenSymbols.indexOf(p.projectTokenSymbol) > -1) {
      return null;
    }

    tokenSymbols.push(p.projectTokenSymbol);
    let [stake, unstake, withdrawal, rewards, usdPrice] = [0, 0, 0, 0, 0];
    stakingProjects
      .filter(d => d.projectTokenSymbol === p.projectTokenSymbol)
      .forEach(item => {
        usdPrice = item.usdPrice;
        stake = BigNumber(stake).plus(BigNumber(item.stake));
        unstake = BigNumber(unstake).plus(BigNumber(item.unstake));
        rewards = BigNumber(rewards).plus(BigNumber(item.rewards));
        if (withdrawal === 0) {
          withdrawal = parseInt(item.withdrawal);
        } else if (withdrawal > parseInt(item.withdrawal) && parseInt(item.withdrawal) !== 0) {
          withdrawal = parseInt(item.withdrawal);
        }
      });
    return {
      stake,
      unstake,
      rewards,
      withdrawal,
      usdPrice,
      project: p
    };
  });

  return stakingByToken
    .filter(p => p != null && (p.stake > 0 || p.unstake > 0 || p.rewards > 0))
    .sort((a, b) => b.stake - a.stake);
}

// TokenPane displays all the tokens and filter / toggle controls.
function TokenPane(props) {
  if (props.stakingProjects.length === 0) {
    return null;
  }

  const stakingByToken = aggregateStakingByToken(props.stakingProjects);

  return (
    <ul className="bg-white p-10 pt-2 mx-auto rounded-lg">
      <div className="inline-flex rounded-md shadow-sm float-right" role="group">
        <button
          type="button"
          onClick={() => {
            props.setShowPrice(true);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-l-lg border border-blue-900 hover:bg-blue-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
          <i className="fa fa-dollar-sign"></i>
          Value
        </button>
        <button
          type="button"
          onClick={() => {
            props.setShowPrice(false);
          }}
          className="inline-flex items-center py-2 px-4 text-sm font-medium text-gray-900 bg-transparent rounded-r-lg border border-blue-900 blue-gray-900 hover:bg-blue-900 hover:text-white focus:z-10 focus:ring-2 focus:ring-blue-500 focus:bg-blue-900 focus:text-white dark:border-white dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:bg-gray-700">
          <i className="fas fa-coins" />
          Token
        </button>
      </div>
      <div className="inline-flex pt-3 w-full">&nbsp;</div>
      {stakingByToken.map(p => {
        const stake = (props.showPrice ? Number(p.stake) * p.usdPrice : Number(p.stake)).toFixed(2);
        const unstake = (
          props.showPrice ? Number(p.unstake) * p.usdPrice : p.unstake
        ).toFixed(2);
        // const lastStaked = moment.unix(p.lastStakedDate).fromNow();
        // const lastUnstaked = moment.unix(p.lastUnstakedDate).fromNow();
        const withdrawal = p.withdrawal > 0 ? moment.unix(p.withdrawal).fromNow() : 'none';
        const rewards = (
          props.showPrice ? Number(p.rewards) * p.usdPrice : p.rewards
        ).toFixed(2);
        return (
          <Token
            p={p}
            setCurrentToken={props.setCurrentToken}
            setShowModal={props.setShowModal}
            stakingProjects={props.stakingProjects}
            showPrice={props.showPrice}
            stake={stake}
            rewards={rewards}
            unstake={unstake}
            withdrawal={withdrawal}
          />
        );
      })}
    </ul>
  );
}

// StakingPage displays the full staking page.
function StakingPage(props) {

  return (
    <div className=" v-screen flex justify-center items-center">
      <div className="container mx-auto min-w-full min-h-screen bg-blue-600 bg-blue-gradient">
        <Header syncButton={true} onSyncClick={() => props.syncStaking()} active="/staking" />
        <Message type={props.error.type} message={props.error.message} />
        <div className="container mx-auto flex my-10">
          <TokenPane
            setCurrentToken={props.setCurrentToken}
            setShowModal={props.setShowModal}
            stakingProjects={props.stakingProjects}
            showPrice={props.showPrice}
            setShowPrice={props.setShowPrice}
          />
        </div>
        <Dialog size="lg" open={props.showModal} handler={() => props.setShowModal(false)}>
          <DialogBody>
            <StakingByProject
              currentToken={props.currentToken}
              stakingProjects={props.stakingProjects}
            />
          </DialogBody>
        </Dialog>
      </div>
    </div>
  );
}

export default Staking;
