import { useContext, useState } from "react";
import { StakingContext } from '../../context/StakingContext';
import { AiFillPlayCircle } from "react-icons/ai";
import { SiEthereum } from "react-icons/si";
import { BsInfoCircle } from "react-icons/bs";

import Model from "../Model";
import Message from "./Message";
import { ClockLoader } from "react-spinners";
import Header from "../Header";

const Input = ({ placeholder, name, type, value, handleChange, readonly = false, max, addtitionalClass = "outline-none text-dark" }) => (
  <input
    placeholder={placeholder}
    type={type}
    step="1"
    max={type === "number" ? max : ""}
    value={value}
    readOnly={readonly}
    onChange={(e) => handleChange(e, name)}
    className={`my-2 w-full rounded-sm p-2 ${addtitionalClass} text-sm blue-glassmorphism`}
  />
);

const IDVTStaking = () => {
  const { actionNotification, currentAccount, balance, stakeAmount, connectWallet, handleChange, formData, isLoading, stakeToken, tokenSymbol, rewardAmount, earnedAmount, allowance, approveTransaction, withdraw, withdrawForce, claimRewards, lockupPeriod } = useContext(StakingContext);
  const [showWithdrawlModel, setShowWithdrawlModel] = useState(false);
  const handleSubmit = (e) => {
    const { tokenAddress, amount } = formData;

    e.preventDefault();

    if (!amount) return;

    stakeToken();
  };
  const handleApprove = (e) => {
    const { amount } = formData;

    e.preventDefault();
    if (!amount) return;

    approveTransaction(amount);
  };
  const lockingPeriodDaysLeft = () => {
    if (lockupPeriod) {
      const daysLeft = Math.floor(((lockupPeriod * 1000) - (Date.now())) / (1000 * 60 * 60 * 24));
      return daysLeft;
    }
    return 0;
  }
  return (
    <div className=" v-screen flex justify-center items-center">
      <div className="container mx-auto min-w-full min-h-screen bg-blue-600 bg-blue-gradient">
        <Header active="/idvtstake" />
        <div className="container mx-auto flex my-10">
          <div className="bg-white py-5  w-full md:w-3/4 lg:w-1/1 mx-auto rounded-lg">
            <div className="flex w-full justify-center items-center">
              <div className="flex mf:flex-row items-start justify-between md:p-20 py-12 px-4">
                <div className="flex flex-1 justify-start items-start flex-col mf:mr-10">

                  <h1 className="text-3xl sm:text-5xl text-dark py-1">
                    Stake your token <br /> To unlock more
                  </h1>
                  <p className="text-left mt-5 text-dark font-light md:w-9/12 w-11/12 text-base">
                    Use this platform to stake your token and earn rewards.
                  </p>
                  {currentAccount && (
                    <div className="p-5 sm:w-96 w-full flex flex-col justify-start items-center blue-glassmorphism">
                      <h2 className="text-white text-xl">Staking Window</h2>
                      <hr color="white" className="w-full" />
                      <p className="text-white 4xl mt-4">Available Token {parseFloat(balance).toFixed(5)} {tokenSymbol}</p>
                      <Input placeholder="Amount To Approve" name="amount" type="number" value={formData.amount} handleChange={handleChange} />
                      {allowance > 0 &&
                        <span className="text-xs text-white">Approved Balance: {allowance} {tokenSymbol}</span>
                      }
                      {isLoading && (actionNotification.origin === 'approve' || actionNotification.origin === 'stake')
                        ? <ClockLoader color="lightBlue" class="mx-auto" loading={isLoading} size={150} />
                        : (
                          <>                <div className="flex flex-row">
                            <button
                              type="button"
                              onClick={handleApprove}
                              className="text-white w-32 mt-2 mx-5 border-[1px] px-3 border-[#3d4f7c] hover:bg-[#3d4f7c] rounded-full cursor-pointer"
                            >
                              Approve
                            </button>
                            {allowance > 0 &&
                              <button
                                type="button"
                                onClick={handleSubmit}
                                className="text-white w-32 mt-2 mx-5 border-[1px] px-3 border-[#3d4f7c] hover:bg-[#3d4f7c] rounded-full cursor-pointer"
                              >
                                Stake
                              </button>
                            }

                          </div>
                            <div className="flex flex-row">
                              <Message title={actionNotification.title} message={actionNotification.message} messageType={actionNotification.messageType} />

                            </div>
                          </>

                        )}
                    </div>
                  )}
                </div>
                {currentAccount && (
                  <div className="flex flex-col flex-2 items-center justify-start mf:mt-0 mt-10">
                    <div className="p-3 flex justify-end items-start flex-col rounded-xl h-40 sm:w-96 w-full my-5 blue-glassmorphism ">
                      <div className="flex justify-between flex-col w-full h-full">
                        <div className="flex justify-between items-start">
                          <div className="w-10 h-10 rounded-full border-2 border-white flex justify-center items-center">
                            <SiEthereum fontSize={21} color="#fff" />
                          </div>

                          <h3 className="text-white text-3xl">Staked Token</h3>
                          <BsInfoCircle fontSize={17} color="#fff" />
                        </div>
                        <div>
                          <p className="text-white text-center text-4xl mb-5">{stakeAmount} {tokenSymbol}</p>
                          <button className="float-right text-pink-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                            onClick={() => { setShowWithdrawlModel(!showWithdrawlModel) }} type="button">Withdraw</button>

                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col flex-1 items-center justify-start w-full mf:mt-0 mt-10">
                      <div className="p-3 flex justify-end items-start flex-col rounded-xl h-40 sm:w-96 w-full my-5 blue-glassmorphism ">
                        <div className="flex justify-between flex-col w-full h-full">
                          <div className="flex justify-between items-start">
                            <div className="w-10 h-10 rounded-full border-2 border-white flex justify-center items-center">
                              <SiEthereum fontSize={21} color="#fff" />
                            </div>

                            <h3 className="text-white text-3xl">Rewards</h3>
                            <BsInfoCircle fontSize={17} color="#fff" />
                          </div>
                          <div>
                            <p className="text-white text-center text-4xl mb-5">{(parseFloat(earnedAmount) + parseFloat(rewardAmount))} {tokenSymbol}</p>
                            <button className="float-right text-pink-500 background-transparent font-bold uppercase px-3 py-1 text-xs outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                              onClick={async () => {
                                await claimRewards();
                              }} type="button">{(isLoading && actionNotification.origin === 'claim') ? 'Processing...' : 'Claim'}</button>
                          </div>

                        </div>


                        <div className="flex flex-row">
                          <Message title={actionNotification.title} message={actionNotification.message} messageType={actionNotification.messageType} />

                        </div>
                      </div>
                    </div>

                  </div>
                )}
              </div>
              <Model handleOk={() => { withdraw() }} isLoading={actionNotification.origin === 'withdraw' && isLoading} handleClose={() => { setShowWithdrawlModel(false) }} shown={showWithdrawlModel} modelId="withdrawModel" title="Withraw Token">
                <Input placeholder="Amount" addtitionalClass="outline-none border-slate-300" max={stakeAmount} name="withdrawAmount" type="number" value={formData.withdrawAmount} handleChange={handleChange} />
                <p className="text-xs">Enter any amount less then {stakeAmount}</p>
                {
                  actionNotification && actionNotification.origin === 'withdraw' && (
                    <Message message={actionNotification.message} messageType={actionNotification.messageType} />
                  )
                }
                {lockupPeriod && lockupPeriod > ((new Date()).getTime() / 1000) &&
                  <div className="flex flex-row text-xs text-red-700">
                    You are not allowed to withdraw before {lockingPeriodDaysLeft()} locking Days.<br />
                    If you still want to withdraw , you can withdraw but 50% of your stake will be charged as fee.
                    <button type="button"
                      onClick={() => withdrawForce()}
                      disabled={isLoading}
                      className="px-6
        py-2.5 w-1/2
        bg-blue-600
        text-dark
        font-medium
        text-xs
        leading-tight
        uppercase
        rounded-full
        shadow-md
        hover:bg-blue-700 hover:shadow-lg
        focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0
        active:bg-blue-800 active:shadow-lg
        transition
        duration-150
        ease-in-out
        ml-1"> {isLoading ? 'Processing...' : 'Withdraw Anyway'}</button>
                  </div>
                }

              </Model>
            </div></div></div>
      </div></div>
  );
}
export default IDVTStaking;
